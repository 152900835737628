import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { graphql, Link, StaticQuery } from "gatsby";
import Layout from "../../components/Layout";
import Map from "../../components/Map";
import Cta from "../../components/Cta";
import BubbleSection from "../../components/BubbleSection";
import doctroPlaceholderFemale from "../../img/doctor_female.svg";
import doctroPlaceholderMale from "../../img/doctor_male.svg";

import "../../styles/about.scss";

import { LOCATIONS } from "../../config/cities";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";
import DoctorList from "../../components/about/DoctorList";
import { GTM_LEAD_UMOW_SIE } from "../../config/gtm_classes";

const DOCTORS_POZNAN = [
  {
    name: "dr n. med. Łukasz Galus",
    title: "specjalista onkologii klinicznej",
    description:
      "Absolwent Uniwersytetu Medycznego im. Karola Marcinkowskiego w Poznaniu na Wydziale Lekarskim. Jest specjalistą w dziedzinie onkologii klinicznej. Pracuje w Szpitalu Klinicznym im. Heliodora Święcickiego Uniwersytetu Medycznego im. Karola Marcinkowskiego w Poznaniu na Oddziale Onkologii Klinicznej i Doświadczalnej oraz w Wielkopolskim Centrum Onkologii. Członek PTOK (Polskiego Towarzystwa Onkologii Klinicznej) oraz ESMO (European Society of Medical Oncology). Autor publikacji w czasopismach naukowych. Bierze udział w wielu badaniach klinicznych (próbach eksperymentalnych) oceniających nowe leki stosowane u chorych na nowotwory złośliwe. Jego zainteresowania i główny zakres działalności klinicznej obejmuje pacjentów cierpiących z powodu nowotworów skóry w szczególności czerniaka. Strefa zainteresowań naukowych obejmuje nowe strategie leczenia systemowego w tym immunoterapię nowotworów i możliwości jej wykorzystania w praktyce leczniczej. Rolą onkologa klinicznego jest integracja wszystkich metod leczenia przeciwnowotworowego - planowanie i koordynacja leczenia chirurgicznego, radioterapii i terapii systemowej dlatego onkolog pełni kluczową rolę w procesie diagnostyczno-terapeutycznym pacjenta z chorobą nowotworową.",
    city: "Poznań",
    img: "lukasz_galus.png",
  },
  {
    name: "dr n. med. Dorota Domagalska",
    title: "specjalizuje się w chirurgii",
    description:
      "Absolwentka Uniwersytetu Medycznego imienia Karola Marcinkowskiego w Poznaniu na wydziale lekarskim. Pracuje w Uniwersyteckim Szpitalu Klinicznym w Poznaniu, na Oddziale Chirurgii Ogólnej, Endokrynologicznej i Onkologii Gastroenterologicznej, gdzie odbywa ostatni rok szkolenia specjalizacyjnego z chirurgii ogólnej oraz prowadzi zajęcia ze studentami jako nauczyciel akademicki. W 2023 roku obroniła przewód doktorski uzyskując tytuł doktora nauk medycznych i nauk o zdrowiu. Autorka licznych artykułów i prac naukowych. Członek Towarzystwa Chirurgów Polskich. Stale podnosi swoje umiejętności na licznych kursach i konferencjach. W codziennej pracy zajmuje się kompleksową diagnostyką i leczeniem dermatoonkologicznym i dermatochirurgicznym- w naszym ośrodku wykonuje badania wideodermatoskopowe dorosłych i dzieci, przeprowadza zabiegi zarówno chirurgicznego jak i laserowego usuwania zmian skórnych, pobierania biopsji. Ponadto zajmuje się poradnictwem z zakresu chirurgii ogólnej, endokrynologicznej i przewodu pokarmowego.",
    city: "Poznań",
    img: "dorota_domagalska.png",
  },
  {
    name: "dr n. med. Marlena Janiczek-Polewska",
    title: "specjalizuje się w onkologii klinicznej",
    description:
      "Ukończyła studia na kierunku lekarskim na Uniwersytecie Mikołaja Kopernika W Toruniu. W trakcie specjalizacji z onkologii klinicznej oraz studiów podyplomowych z medycyny estetycznej. Tytuł doktora nauk medycznych i nauk o zdrowiu uzyskała w 2020 roku na Uniwersytecie Medycznym im. Karola Marcinkowskiego w Poznaniu. Autorka publikacji naukowych z zakresu onkologii klinicznej i patomorfologii. Ukończyła studia podyplomowe w zakresie prawa medycznego, zarządzania w ochronie zdrowia oraz badań klinicznych i metodologii badań naukowych. Członkini Polskiego Towarzystwa Onkologicznego, Onkologii Klinicznej i Europejskiej Szkoły Onkologicznej. Uczestniczy także w licznych konferencjach, szkoleniach i kursach poświęconych nowotworom skóry i dermatoskopii.",
    city: "Poznań",
    img: "marlena_janiczek.png",
  },
  {
    name: "Joanna Przybyszewska Goldberg",
    title: "specjalizuje się w dermatologii i wenerologii",
    description:
      "Ukończyła studia medyczne na Wydziale Lekarskim Warszawskiego Uniwersytetu Medycznego. Podczas studiów odbyła roczny wyjazd stypendialny na Uniwersytecie Hebrajskim w Jerozolimie, w Hadassah Medical Center Ein Kerem, poszerzając wiedzę i opracowując publikacje na temat chorób genetycznych skóry. Obecnie przebywa na stypendium na Oddziale Dermatologii Uniwersytetu George'a Washingtona w Waszyngtonie, USA. Poza diagnostyka znamion barwnikowych zajmuje się m in. diagnostyką, leczeniem i prowadzeniem pacjentów z AZS i innymi chorobami zapalnymi skóry. W pracy klinicznej sięga po najnowsze doniesienia naukowe, leczy pacjentów według standardów medycyny opartej na faktach (EBM - z ang. evidence-based medicine). Uczestniczy w konferencjach i szkoleniach poświęconych nowotworom skóry.",
    city: "Poznań",
    img: "joanna_goldberg.png",
  },
  {
    name: "Wojciech Siejak",
    title: "",
    description:
      "Ukończył studia na Wydziale Lekarskim Pomorskiego Uniwersytetu Medycznego w Szczecinie. Obecnie odbywa szkolenie specjalizacyjne w zakresie chirurgii onkologicznej na Oddziale Chirurgii Onkologicznej Chorób Piersi w Wielkopolskim Centrum Onkologii w Poznaniu. Członek Polskiego Towarzystwa Chirurgii Onkologicznej (PTChO) oraz Europejskiego Towarzystwa Chirurgii Onkologicznej (ESSO – European Society of Surgical Oncology). Głównym zakresem zainteresowań są nowotwory gruczołu piersiowego, chirurgia onkoplastyczna i rekonstrukcyjna gruczołu piersiowego, nowotwory skóry oraz czerniak. Stale podnosi swoje kwalifikacje w trakcie kurów i staży w Polsce (Oddział Chirurgii Plastycznej Specjalistycznego Centrum Medycznego w Polanicy-Zdroju) i za granicą (Leiden University Medical Center, Clínica Universidad de Navarra Madryt). W Naszym Centrum w Poznaniu przeprowadza zabiegi w zakresie chirurgicznego i laserowego usuwania znamion skórnych.",
    city: "Poznań",
    img: "wojciech_siejak.png",
  },
  {
    name: "Gabriela Olender",
    title: "",
    description:
      "Absolwentka Uniwersytetu Medycznego im. Karola Marcinkowskiego w Poznaniu na Wydziale Lekarskim. Jej pasją jest profilaktyka onkologiczna oraz metody wczesnego wykrywania nowotworów. Podchodzi do pacjenta całościowo. W trakcie szkolenia specjalizacyjnego z Medycyny Rodzinnej. Członek International Dermoscopy Society oraz Polskiego Towarzystwa Medycyny Rodzinnej. Swoją wiedzę nieustannie poszerza na polskich i międzynarodowych szkoleniach oraz konferencjach. Doświadczenie w zakresie dermatoskopii zdobywała pod okiem dr n. med. Jacka Calika w Akademii Dermatoskopii.",
    city: "Poznań",
    img: "gabriela_olender.png",
  },
  {
    name: "Krzysztof Krajewski",
    title: "",
    description:
      "Absolwent Wydziału Lekarskiego Uniwersytetu Medycznego im. Karola Marcinkowskiego w Poznaniu. Staż podyplomowy odbył w Szpitalu Wojewódzkim w Poznaniu. W trakcie studiów uczestniczył w wielu konferencjach, między innymi o tematyce onkologicznej. Doświadczenie w dermatoskopii zbierał na szkoleniach. Uczestniczył w Akademii Dermatoskopii organizowanej przez doktora Jacka Calika. Pasjonat medycyny, w szczególności zainteresowany onkologią kliniczną oraz dermatologią kliniczną. Swoją wiedzę doskonali w oparciu o aktualne wytyczne i najnowsze doniesienia naukowe.",
    city: "Poznań",
    img: "krajewski.png",
  },
  {
    name: "Andrzej Tomaszewski",
    title: "",
    description:
      "Absolwent Uniwersytetu Medycznego imienia Karola Marcinkowskiego w Poznaniu na wydziale lekarskim. Obecnie odbywa szkolenie specjalizacyjne w zakresie chirurgii na Oddziale Chirurgii Onkologicznej Chorób Przewodu Pokarmowego w Wielkopolskim Centrum Onkologii w Poznaniu. Członek Towarzystwa Chirurgów Polskich. Stale podnosi swoje umiejętności na licznych kursach i konferencjach. W codziennej pracy zajmuje się kompleksową diagnostyką i leczeniem nowotworów przewodu pokarmowego i skóry - w naszym ośrodku przeprowadza zabiegi chirurgicznego usuwania zmian skórnych, pobierania biopsji. Ponadto zajmuje się poradnictwem z zakresu chirurgii ogólnej.",
    city: "Poznań",
    img: "AndrzejTomaszewski.png",
  },

  {
    name: "Milena Magdzińska",
    title: "",
    description: "Absolwentka Wydziału Lekarskiego Uniwersytetu w Kielcach Collegium Medicum, gdzie uzyskała tytuł lekarza. W trakcie studiów uczestniczyła w międzynarodowej wymianie, spędzając dwa lata na nauce we Włoszech, na Università di Verona oraz Università di Parma. Bogate doświadczenie kliniczne zdobywała podczas licznych praktyk zawodowych, a także odbywając staż dyplomowy na Università di Verona w Weronie. Umiejętności w zakresie dermatoskopii rozwijała pod kierunkiem dr n med. Jacka Calika w Akademii Dermatoskopii. Jej wszechstronne wykształcenie, międzynarodowe doświadczenie oraz zaangażowanie w praktykę medyczną przekładają się na wysoki standard opieki nad pacjentami.",
    city: "Poznań",
    img: "Milena-Magdzinska.png",
  },
  {
    name: "Maciej Filipowski",
    title: "",
    description: "Absolwent Wydziału Lekarskiego Collegium Medicum im. Ludwika Rydygiera w Bydgoszczy Uniwersytetu Mikołaja Kopernika w Toruniu . Obecnie pracuje na oddziale Chirurgii Dziecięcej i Leczenia Oparzeń w Wielkopolskim Centrum Pediatrii. Uzyskał certyfikat ukończenia kursu wideodermatoskopii organizowanego przez Akademię Dermatoskopii Dr Jacka Calika. Stale poszerza swoją wiedzę i umiejętności poprzez uczestnictwo w szkoleniach wideodermatoskopii. Członek Polskiej Grupy Dermatoskopowej.",
    city: "Poznań",
    img: "MaciejFilipowski.png",
  },

  {
    name: "Roksana Ostrowska",
    title: "",
    description: "Absolwentka Wydziału Lekarskiego Collegium Medicum im. Ludwika Rydygiera w Bydgoszczy, będącego częścią Uniwersytetu Mikołaja Kopernika w Toruniu. W 2023 roku uzyskała tytuł specjalisty w dziedzinie chirurgii ogólnej. Na co dzień pracuje w Oddziale Chirurgii Ogólnej Szpitala w Łasinie, gdzie poszerza swoją wiedzę i doskonali umiejętności zabiegowe, uczestnicząc w kursach i konferencjach naukowych. Szczególną uwagę poświęca dermatochirurgii oraz leczeniu pacjentów z nowotworami skóry, w tym z czerniakiem. Dodatkowo zajmuje się terapią trudno gojących się ran przewlekłych, w tym terapią podciśnieniową VAC, oraz leczeniem żywieniowym, które odgrywa kluczową rolę w rekonwalescencji pacjentów po interwencjach chirurgicznych. W Twoich Znamionach w Poznaniu przeprowadza chirurgiczne usuwanie znamion, wykorzystując swoją wiedzę i doświadczenie w zakresie nowoczesnych technik zabiegowych.",
    city: "Poznań",
    img: "Roksana-Ostrowska.png",
  },
  
];
const DOCTORS_WARSZAWA = [
  {
    name: "Anna Pasierb",
    title: "",
    description:
      "Absolwentka I Wydziału Lekarskiego Warszawskiego Uniwersytetu Medycznego. Posiada doświadczenie w ocenie dermoskopowej znamion. Certyfikowany lekarz Medycyny Stylu Życia przez International Board of Lifestyle Medicine oraz European Health and Fitness Lifestyle Advisor. Członek Polskiego Towarzystwa Dermatologicznego, European Association of Dermatology and Venerology, International Dermoscopy Society oraz Polskiego Towarzystwa Medycyny Stylu Życia. Nieustannie poszerza swoją wiedzę i umiejętności na licznych szkoleniach, kursach oraz konferencjach. Współautorka publikacji naukowych z zakresu dermatologii oraz endokrynologii. Laureatka nagrody Grand Prix of 12th WIMC. W 2016 roku odbyła staż naukowy w ośrodku Lausanne University Hospital CHUV w Szwajcarii.",
    city: "Warszawa",
    img: "annapasierb.png",
  },
  {
    name: "Sylwia Kopeć",
    title: "specjalizuje się w onkologii klinicznej",
    description:
      "Absolwentka Wydziału Lekarskiego Uniwersytetu Jagiellońskiego – Collegium Medicum w Krakowie. Obecnie jest w trakcie specjalizacji z onkologii klinicznej, specjalizację realizuje w Klinice Nowotworów Tkanek Miękkich, Kości i Czerniaków Narodowego Instytutu Onkologii im. M. Skłodowskiej-Curie w Warszawie, gdzie również pracuje w Poradni Profilaktyki Nowotworów Skóry w Centrum Profilaktyki Nowotworów. Odbyła liczne kursy z zakresu dermatoskopii, uczestniczyła w konferencjach, szkoleniach dotyczących czerniaka i innych nowotworów skóry. Tematem badania znamion i wykrywania nowotworów skóry zainteresowała się już w trakcie stażu podyplomowego odbywając staże zagraniczne m in. w klinice uniwersyteckiej Dermatologii i Wenerologii (Univertätklinikum Essen) w Niemczech, na oddziale Dermatologii i Chirurgii Plastycznej we Włoszech, w dwóch klinkach uniwersyteckich - Policlinico Tor Vergata oraz Campus Bio-Medico di Roma.",
    city: "Warszawa",
    img: "sylwiakopec.png",
  },

  {
    name: "Piotr Celmer",
    title: "specjalizuje się w okulistyce",
    description:
      "Absolwent I wydziału lekarskiego Warszawskiego Uniwersytetu Medycznego, aktualnie pracuje na oddziale okulistycznym szpitala MSWiA gdzie w przyszłości chciałby się zająć mikrochirurgią oka. W swojej pracy wykorzystuje najnowsze doniesienia naukowe, uczestniczy w konferencjach i szkoleniach poświęconych między innymi nowotworom skóry. Posiada doświadczenie w ocenie dermatoskopowej znamion. Współautor licznych prac z zakresu dermatologii, w tym nagrodzonej: Imikwimod w leczeniu lentigo maligna na V Ogólnopolskiej Konferencji Naukowej 'Interdyscyplinarne aspekty chorób skóry i błon śluzowych'. W czasie wolnym poświęca się działalności naukowej - autor '30 dni do matury z chemii - kursoksiążka' - przygotowanej z wydawnictwem Krzysztof Pazdro.",
    city: "Warszawa",
    img: "piotr_celmer.jpeg",
  },
  {
    name: "Łukasz Kalinowski",
    title: "specjalizuje się w chirurgii onkologicznej",
    description:
      "Ukończył Warszawski Uniwersytet Medyczny. Obecnie odbywa specjalizację z Chirurgii Onkologicznej w Klinice Nowotworów Piersi i Chirurgii Rekonstrukcyjnej w Narodowym Instytucie Onkologii im. Marii Skłodowskiej-Curie Państwowym Instytucie Badawczym w Warszawie. Przeprowadza zabiegi z zakresu chirurgii ogólnej i onkologicznej. Jest autorem licznych wystąpień na kongresach naukowych o zasięgu międzynarodowym oraz krajowym. Stale podnosi swoje kwalifikacje w trakcie kursów czy staży zagranicznych m in. w Szpitalu Uniwersyteckim La Paz w Madrycie. Członek Polskiego Towarzystwa Chirurgii Onkologicznej.",
    city: "Warszawa",
    img: "kalinowski.png",
  },
  {
    name: "Anita Wandyszewska",
    title: "specjalizuje się w chirurgii plastycznej",
    description:
      "Absolwentka Wydziału lekarskiego Uniwersytetu Medycznego imienia Piastow Śląskich we Wrocławiu. Doświadczenie w zakresie chirurgii ogólnej zdobywała w Oddziale Chirurgii Ogólnej i Małoinwazyjnej w Ośrodku Badawczo-Rozwojowym WSS we Wrocławiu. Obecnie w trakcie szkolenia specjalizacyjnego z zakresu chirurgii plastycznej w Oddziale Klinicznym Chirurgii Plastycznej w Szpitalu Klinicznym im. prof. W. Orłowskiego CMKP w Warszawie, gdzie stale podnosi swoje kwalifikacje zawodowe m in. w zakresie leczenia chirurgicznego nowotworów skóry.",
    city: "Warszawa",
    img: "anita_wandyszewska.png",
  },
  {
    name: "Katarzyna Babkiewicz",
    title: "",
    description:
      "Absolwentka Uniwersytetu Medycznego w Łodzi na Wydziale Lekarskim. Ukończyła podyplomowe studia Akademia Medycyny Estetycznej i Biznesu na Uniwersytecie Leona Koźmińskiego w Warszawie. Doświadczenie w dermatoskopii zdobywała na kursach oraz szkoleniach, w tym w Akademii Dermatoskopii dr n.med. Jacka Calika we Wrocławiu. Stale poszerza swoją wiedzę i umiejętności uczestnicząc w konferencjach naukowych opierając się na aktualnych standardach medycznych. Jest członkiem Polskiego Towarzystwa Onkologicznego PTO oraz Polskiego Towarzystwa Medycyny Estetycznej i Anti-Aging PTMEiAA przy Polskim Towarzystwie Lekarskim. Kładzie duży nacisk na podnoszenie kwalifikacji poprzez aktywny udział w kursach i szkoleniach zarówno z zakresu dermatoskopii, jak i medycyny estetycznej akredytowanych m in. przez Okręgowa Izbę Lekarską w Warszawie (m in. E-med Academy). Każdego pacjenta traktuje indywidualnie i z należytą troską. Dokładnie wyjaśnia przebieg leczenia i odpowiada na pytania. Mając na uwadze komfort i bezpieczeństwo pacjenta, dba o przyjazną atmosferę podczas wizyty. Brała czynny udział w akcjach profilaktycznych “Mam Haka na Raka” w zakresie wczesnego wykrywania czerniaka i nowotworów skóry.",
    city: "Warszawa",
    img: "Babkiewicz.png",
  },
  {
    name: "Paulina Gorloff",
    title: "",
    description:
      "Absolwentka Warszawskiego Uniwersytetu Medycznego. Dermatoskopią i badaniem znamion zainteresowała się już na studiach. Od tego czasu regularnie poszerza swoją wiedzę w tym zakresie na konferencjach i szkoleniach m in. uczestniczyła w Akademii Dermatoskopii dr Jacka Calika. Aktywna członkini International Dermatoscopy Society. Laureatka I miejsca oraz nagrody specjalnej Redaktor Naczelnej Przeglądu Dermatologicznego VII edycji Ogólnopolskiej i Międzynarodowej Konferencji Naukowej „Interdyscyplinarne Aspekty Chorób Skóry i Błon Śluzowych”.",
    city: "Warszawa",
    img: "cieply.png",
  },
  {
    name: "Paweł Falkowski",
    title: "specjalista medycyny rodzinnej",
    description:
      "Specjalista medycyny rodzinnej, absolwent Uniwersytetu medycznego w Białymstoku. Poszukując nowych metod diagnostycznych w 2017 roku rozpoczął szkolenie z zakresu dermatoskopii, która stała się jego największą pasją. Od tego czasu aktywnie uczestniczy w licznych szkoleniach i konferencjach w kraju oraz zagranicą zarówno jako słuchacz, jak i wykładowca. W 2018 roku otrzymał uznany przez Międzynarodowe Towarzystwo Dermatoskopowe certyfikat Dermoscopy Diploma of Excellence (Rzym). W 2019 roku rozpoczął studia na prestiżowym kierunku Master of Science in Dermoscopy and Preventic Dermatooncology na Uniwersytecie Medycznym w Graz w Austrii. Jest członkiem International Dermoscopy Society oraz Polskiego Towarzystwa Medycyny Rodzinnej.",
    city: "Warszawa",
    img: doctroPlaceholderMale,
  },

  {
    name: "Aleksandra Tatka",
    title: "",
    description: "Absolwentka Wydziału Lekarskiego Uniwersytetu Medycznego w Łodzi. Doświadczenie w dermatoskopii zdobywała na kursach oraz szkoleniach, w tym w Akademii Dermatoskopii dr n.med. Jacka Calika we Wrocławiu. W swojej pracy wykorzystuje najnowsze doniesienia naukowe. Nieustannie podnosi kwalifikacje i poszerza wiedzę poprzez aktywny udział w licznych kursach, szkoleniach i konferencjach z zakresu dermatoskopii.  Współautorka publikacji naukowych z zakresu chorób i nowotworów skóry. Ukończyła podyplomowe studia z zakresu Medycyny Estetycznej na SWSM w Katowicach. Jest członkiem International Dermoscopy Society oraz Polskiego Towarzystwa Dermatologicznego. Każdego pacjenta traktuje indywidualnie. Dokładnie wyjaśnia przebieg leczenia i odpowiada na pytania. Pacjenci cenią komfort i bezpieczeństwo oraz przyjazną atmosferę podczas wizyty",
    city: "Warszawa",
    img: doctroPlaceholderFemale,
  },

  {
    name: "Anna Kowalczyk",
    title: "",
    description: "",
    city: "Warszawa",
    img: doctroPlaceholderFemale,
  },
  {
    name: "Aleksandra Górecka",
    title: "",
    description: "Absolwentka Wydziału Lekarskiego Uniwersytetu Medycznego im. Piastów Śląskich we Wrocławiu. Aktualnie odbywa szkolenie specjalizacyjne w Klinice Dermatologii Państwowego Instytutu Medycznego MSWiA. Jest członkinią International Dermoscopy Society (IDS), European Association of Dermato-Oncology (EADO), European Academy of Dermatology and Venereology (EADV) oraz Polskiego Towarzystwa Dermatologicznego.",
    city: "Warszawa",
    img: "Aleksandra-Gorecka.png",
  },

  {
    name: "Adam Gryglas",
    title: "",
    description: "",
    city: "Warszawa",
    img: doctroPlaceholderMale,
  },
  {
    name: "Anna Szabrańska",
    title: "",
    description:
      "Ukończyła w 2022 roku studia na Wydziale Ldekarskim na Uniwersytecie Medycznym w Łodzi, a następnie odbyła staż podyplomowy w szpitalu USK im. WAM w Łodzi. Jest współautorką publikacji z dziedzin dermatologii, onkologii i psychiatrii. Doświadczenie w dermatoskopii zbierała na kursach oraz szkoleniach, w tym na Kursie Dermatoskopowym na Akademii Dermatoskopii. Stale poszerza swoją wiedzę i umiejętności opierając się o najnowsze doniesienia naukowe, angażując się w ciągłe doskonalenie zawodowe.",
    city: "Warszawa",
    img: "anna_szabranska.png",
  },
];
const DOCTORS_SZCZECIN = [
  {
    name: "Iwona Bielawska-Wierzbińska",
    title: "",
    description:
      "Absolwentka Wydziału Lekarskiego Gdańskiego Uniwersytetu Medycznego. Tytuł specjalisty w dziedzinie Dermatologii i Wenerologii uzyskała w 2017r. po ukończeniu szkolenia specjalizacyjnego w Klinice Chorób Skórnych i Wenerycznych Samodzielnego Publicznego Szpitala Klinicznego nr 1 Pomorskiego Uniwersytetu Medycznego im. Prof. Tadeusza Sokołowskiego w Szczecinie. Członkini Polskiego Towarzystwa Dermatologicznego oraz Europejskiego Stowarzyszenia Dermato-onkologii.  Od początku kariery zawodowej swoje zainteresowania kierowała ku dermatoskopii. W 2013 r. odbyła staż w ramach Oddziału Nowotworów Skóry Szpitala S. Maria Nuova, Reggio Emilia we Włoszech, gdzie nabywała doświadczenia w zakresie dermatoskopii pod okiem jednych z najwybitniejszych międzynarodowych specjalistów tej dziedziny dermatologii.",
    city: "Szczecin",
    img: "iwona_bielawska_wierzbinska.jpg",
  },

  {
    name: "Magdalena Ogonowska",
    title: "",
    description:
      "Ukończyła z wyróżnieniem Wydział Lekarski Pomorskiego Uniwersytetu Medycznego w Szczecinie. Odbywała staż podyplomowy w Samodzielnym Szpitalu Klinicznym Nr 2 w Szczecinie. Lekarski Egzamin Państwowy zdała z jednym z najlepszych wyników w Polsce. Ukończyła specjalizację z Dermatologii i Wenerologii W Klinice Chorób Skórnych i Wenerycznych w Policach. Jest aktywnym członkiem Polskiego Towarzystwa Dermatologicznego. Promuje profilaktykę nowotworów skóry, nieustannie pogłębia i aktualizuje swoją wiedzę z dermoskopii. Aktywna uczestniczka licznych konferencji, szkoleń i sympozjów z Dermatologii oraz Wenerologii. Poza diagnostyką znamion barwnikowych zajmuje się między innymi leczeniem takich chorób jak: łuszczyca, atopowe zapalenie skóry, łojotokowe zapalenie skóry, trądzik, choroby bakteryjne i wirusowe skóry, wyprysk alergiczny, choroby przenoszone drogą płciową. Zajmuje się problemami skórnymi zarówno dzieci jak i dorosłych.",
    city: "Szczecin",
    img: "magdalena_ogonowska.png",
  },
  {
    name: "Aleksandra Szajko",
    title: "",
    description:
      "Należy do Polskiego Towarzystwa Onkologii Klinicznej oraz Sekcji Dermatologii Onkologicznej.Regularnie uczestniczy w krajowych i zagranicznych szkoleniach z dermatoskopii. Obecnie zajmuje się oceną dermatoskopową, wycinaniem podejrzanych zmian skórnych oraz konsultowaniem pacjentów z rozpoznanym nowotworem skóry pod kątem radykalizacji leczenia lub wskazań do leczenia systemowego (terapie celowane lub immunoterapia w czerniaku). Uważa, że dzięki poznaniu tajników dermatoskopii, samodzielnym wycinaniu podejrzanych zmian oraz rozumieniu języka dermatopatologii regularnie zwiększa umiejętność sprawnej i rzetelnej oceny znamion już na poziomie pierwszej wizyty lekarskiej.",
    city: "Szczecin",
    img: "aleksandra_szajko.png",
  },
  {
    name: "Natalia Saxion",
    title: "",
    description:
      "Absolwentka Wydziału Lekarskiego Pomorskiej Akademii Medycznej w Szczecinie. W 2017 roku uzyskała tytuł lekarza Specjalisty Medycyny Rodzinnej. Od 2018 r pracuje w ośrodku Twoje Znamiona. Od 2022 roku wykonuje drobne zabiegi w zakresie chirurgii skóry oraz laserowego usuwania znamiona skórnych.",
    city: "Szczecin",
    img: "natalia_pisz.jpg",
  },
  {
    name: "Alicja Wajs-Syrenicz",
    title: "",
    description:
      "Absolwentka Wydziału Lekarskiego Pomorskiego Uniwersytetu w Szczecinie. Odbyła staż podyplomowy w Samodzielnym Publicznym Wojewódzkim Szpitalu Zespolonym w Szczecinie. Pracowała m.in. Klinice Chorób Skórnych i Wenerycznych Uniwersyteckiego Szpitala Klinicznego nr 1 w Szczecinie oraz Oddziałach Zakaźnych Samodzielnego Publicznego Szpitala Zespolonego przy ul. Arkońskiej w Szczecinie. Aktualnie pracuje w placówkach Podstawowej Opieki Zdrowotnej. Członkini Polskiego Towarzystwa Dermatologicznego. Ukończyła szkolenia w zakresie dermatoskopii i chirurgii skóry w Akademii Dermatoskopii dr Jacka Calika we Wrocławiu. Systematycznie pogłębia swoją wiedzę i umiejętności uczestnicząc w szkoleniach, kursach i konferencjach.",
    city: "Szczecin",
    img: "Alicja-Wajs-Syrenicz.png",
  },


];
const DOCTORS_WROCLAW = [
  {
    name: "Karolina Świerczyńska-Mróz",
    title: "specjalizuje się w dermatologii i wenerologii",
    description:
      "Absolwentka Wydziału Lekarskiego Uniwersytetu Medycznego im. Piastów Śląskich we Wrocławiu. W grudniu 2024 roku uzyskała stopień naukowy doktora nauk medycznych i nauk o zdrowiu w dyscyplinie nauki medyczne, nadany przez Uniwersytet Medyczny we Wrocławiu. Obecnie odbywa szkolenie specjalizacyjne w Klinice Dermatologii, Wenerologii i Alergologii we Wrocławiu. Członkini European Academy of Dermatology and Venerology oraz Polskiego Towarzystwa Dermatologicznego. Swoją wiedzę z zakresu dermatologii, dermatoskopii oraz medycyny estetycznej nieustannie poszerza na polskich i międzynarodowych szkoleniach oraz konferencjach.",
    city: "Wrocław",
    img: "karolina_swierczynska.png",
  },

  {
    name: "Mirosław Waplak",
    title: "specjalista chirurg onkolog",
    description: "",
    city: "Wrocław",
    img: "miroslaw_waplak.png",
  },
  {
    name: " Iwona Matus",
    title: "lekarz medycyny",
    description:
      "Absolwentka kierunku lekarskiego na Uniwersytecie Medycznym im. Piastów Śląskich we Wrocławiu. W trakcie stażu pełniła funkcję koordynatora największego punktu szczepień we Wrocławiu. Swoją wiedzę z dermatoskopii zdobyła w Akademii Dermatoskopii dr Jacka Calika. W trakcie studiów podyplomowych z medycyny estetycznej na SWSM w Katowicach. Pogłębia swoją wiedzę uczestnicząc w wielu szkoleniach, kursach i konferencjach. Autorka licznych publikacji naukowych.",
    city: "Wrocław",
    img: "iwona_matus.png",
  },
  {
    name: "Konrad Dendys",
    title: "",
    description:
      "Absolwent Wydziału Lekarskiego Uniwersytetu Medycznego im. Piastów Śląskich we Wrocławiu, w trakcie studiów zakwalifikowany na roczną wymianę na Uniwersytet Padewski we Włoszech. Sztuki dermatoskopii uczył się w Akademii Dermatoskopii pod czujnym okiem dr Jacka Calika. Nieustannie poszerza swoją wiedzę i umiejętności na licznych szkoleniach, kursach oraz konferencjach. Autor licznych publikacji naukowych.",
    city: "Wrocław",
    img: "konrad_dendys.png",
  },
  {
    name: "Magdalena Łyko",
    title: "",
    description:
      "Absolwentka Wydziału Lekarskiego Uniwersytetu Medycznego im. Piastów Śląskich we Wrocławiu. Ukończyła studia podyplomowe z zakresu badań klinicznych organizowane przez MCKP Collegium Medicum Uniwersytetu Jagiellońskiego. Obecnie jest doktorantką w Szkole Doktorskiej Uniwersytetu Medycznego we Wrocławiu, działającą w Katedrze i Klinice Dermatologii, Wenerologii i Alergologii. Jest autorką i współautorką artykułów naukowych o tematyce dermatologicznej. Regularnie doskonali swoje kwalifikacje i wiedzę poprzez udział w szkoleniach z zakresu dermatologii i dermatoskopii. Aktywnie uczestniczy w konferencjach krajowych i zagranicznych, pełniąc role zarówno wykładowcy, jak i słuchacza. Jest członkinią Polskiego Towarzystwa Dermatologicznego (PTD) oraz European Academy of Dermatology and Venereology (EADV).",
    city: "Wrocław",
    img: "magdalena_lyko.png",
  },
  {
    name: "Paula Bigos",
    title: "",
    description:
      "Absolwentka Wydziału Lekarskiego Uniwersytetu Medycznego we Wrocławiu. Uzyskała certyfikat ukończenia Kursu Wideodermatoskopii organizowanego przez Akademię Dermatoskopii pod okiem dra Jacka Calika. Nieustannie poszerza swoją wiedzę poprzez uczestnictwo w szkoleniach i konferencjach z zakresu chorób skóry oraz wideodermatoskopii. W obrębie zainteresowań szczególne wyróżnia dermatologię pediatryczną. Szerzenie wiedzy na temat istotności badań profilaktycznych uważa za jedną ze swoich zawodowych misji.",
    city: "Wrocław",
    img: "paula_bigos.png",
  },

  {
    name: "Bartosz Bieżyński",
    title: "",
    description:
      "Dr Bieżyński jest absolwentem Wydziału Lekarskiego Uniwersytetu Medycznego im. Piastów Śląskich we Wrocławiu, a swoją specjalizację z zakresu chirurgii onkologicznej realizuje w Dolnośląskim Centrum Onkologii, Pulmonologii i Hematologii we Wrocławiu na Oddziale Chirurgii II. Swoje zainteresowania koncentruje na diagnostyce i leczeniu nowotworów skóry oraz czerniaka na co dzień pracując w jednym z referencyjnych ośrodków w tym obszarze – Skin Cancer Unit DCOPiH. Szczególnie pasjonuje się dermatochirurgią i chirurgią onkoplastyczną. Członek Polskiego Towarzystwa Chirurgii Onkologicznej (PTChO), czynny uczestnik krajowych i międzynarodowych szkoleń i konferencji z zakresu chirurgii onkologicznej.",
    city: "Wrocław",
    img: "Biezynski.png",
  },
  {
    name: "Kamila Kulczyńska",
    title: "",
    description:
      "Absolwentka Wydziału Lekarskiego Pomorskiego Uniwersytetu Medycznego w Szczecinie. Obecnie odbywa specjalizację z chirurgii ogólnej na Oddziale Chirurgii Ogólnej i Onkologicznej w Uniwersyteckim Szpitalu Klinicznym we Wrocławiu. Ukończyła wiele dodatkowych kursów podnoszących umiejętności, w tym m in. zaawansowany kurs mikrochirurgiczny, kurs chirurgii endoskopowej oraz z zakresu chirurgii małoinwazyjnej i estetycznej.",
    city: "Wrocław",
    img: "Kulczynska.png",
  },
  {
    name: "Karolina Gowans",
    title: "",
    city: "Wrocław",
    description:
      "Specjalistka onkologii klinicznej, posiadająca bogate doświadczenie zdobyte w Dolnośląskim Centrum Onkologii we Wrocławiu na oddziale onkologii klinicznej i chemioterapii, gdzie odbyła staż podyplomowy oraz program specjalizacyjny. Obecnie pracuje jako starszy asystent w Uniwersyteckim Szpitalu Klinicznym we Wrocławiu. Szczególnie interesuje się leczeniem nowotworów złośliwych skóry, przewodu pokarmowego oraz mięsaków tkanek miękkich i kości. W codziennej praktyce szczególną uwagę poświęca wczesnemu wykrywaniu i leczeniu nowotworów skóry, co jest kluczowe w zapobieganiu ich rozwojowi. Specjalizuje się w dermatoskopii i wideodermatoskopii, umożliwiając skuteczną identyfikację zmian skórnych już na etapie przedinwazyjnym. Regularnie uczestniczy w szkoleniach i konferencjach, poszerzając swoją wiedzę i umiejętności. W ramach działalności w Wrocławskim Centrum Dermatoskopowego Badania Znamion, dr Gowans oferuje badania wideodermatoskopowe, onkologiczne konsultacje oraz szerzy wiedzę na temat profilaktyki i leczenia czerniaka.",
    img: "Gowans.png",
  },
  {
    name: "Justyna Putek",
    title: "lekarz medycyny",
    description:
      "Ukończyła Uniwersytet Medyczny im. Piastów Śląskich we Wrocławiu z wyróżnieniem rektora. Podczas studiów uczestniczyła w wielu konferencjach i kongresach naukowych. Autorka prac badawczych i monografii w dziedzinie dermatologii. Spędziła rok na stypendium w Wiedniu, gdzie kontynuowała studia i odbywała praktyki zawodowe. Biegle posługuje się językiem angielskim i niemieckim.",
    city: "Wrocław",
    img: doctroPlaceholderFemale,
  },

  {
    name: "Julia Rymaszewska",
    title: "",
    description:
      "Absolwentka Wydziału Lekarskiego Uniwersytetu Medycznego im. Piastów Śląskich we Wrocławiu. Ukończyła liczne zagraniczne praktyki z zakresu chirurgii szczękowo-twarzowej, chirurgii ogólnej, dermatologii, alergologii i wenerologii. Posiada prawo wykonywania zawodu w Polsce i w Niemczech. Obecnie jest doktorantką w Szkole Doktorskiej Uniwersytetu Medycznego we Wrocławiu w Katedrze i Klinice Dermatologii, Wenerologii i Alergologii gdzie prowadzi wykłady i seminaria kliniczne z zakresu dermatologii ogólnej, alergologii i wenerologii dla kierunku lekarskiego, dietetyki i stomatologii. Jest autorką i współautorką szeregu artykułów w międzynarodowych i uznanych czasopismach naukowych o tematyce psycho-dermatologicznej. Posiada dyplom z zakresu Psychodermatologii (ESDaP European Society for Dermatology and Psychiatry). Ukończyła studia podyplomowe z zakresu Medycyny Estetycznej na Akademii Górnośląskiej. Regularnie doskonali swoje kwalifikacje i wiedzę poprzez udział w szkoleniach z zakresu dermatologii i dermatoskopii. Aktywnie uczestniczy w konferencjach krajowych i zagranicznych, pełniąc role zarówno wykładowcy, jak i słuchacza. Jest członkinią Polskiego Towarzystwa Dermatologicznego (PTD) oraz European Academy of Dermatology and Venereology (EADV)",
    city: "Wrocław",
    img: "rymaszewska.png",
  },
];
const DOCTORS_KRAKOW = [
  {
    name: "Agnieszka Szadurska",
    title: "specjalistka onkologii klinicznej",
    description: "",
    city: "Kraków",
    img: doctroPlaceholderFemale,
  },

  {
    name: "Maria Paziewska",
    title: "",
    description: "",
    city: "Kraków",
    img: doctroPlaceholderFemale,
  },
  {
    name: "Kinga Skotnicka",
    title: "",
    description:
      "Absolwentka Uniwersytetu Medycznego im. Adama Mickiewicza w Poznaniu. Ukończyła studia podyplomowe Clinical Dermatology na University College Dublin. Jest członkinią International Dermoscopy Society. Interesuje ją genetyczne podłoże nowotworów skóry i znamion wrodzonych.",
    city: "Kraków",
    img: "skotnicka_kinga.png",
  },
  {
    name: "Wojciech Piskorski",
    title: "specjalista onkologii klinicznej",
    description:
      "Specjalista Onkologii Klinicznej. Członek Europejskiego Stowarzyszenia Dermatologii Onkologicznej oraz Towarzystwa Onkologii Medycznej. Studia na wydziale lekarskim ukończył w 1992 na Szent-Gyorgy Medical University, Szeged na Węgrzech. Specjalizację I stopnia z zakresu chorób wewnętrznych ukończył w Centrum Kształcenia Podyplomowego w Krakowie a II stopnia z chemioterapii nowotworów w Centrum Kształcenia Podyplomowego w Warszawie. Doświadczenie zawodowe zdobywał w Szpitalu Specjalistycznym im. Rydygiera (Oddział Onkologii Klinicznej) w Krakowie. Uczestniczył w licznych badaniach klinicznych, m in. z zakresu raka piersi i czerniaka skóry. Biegle posługuje się językiem angielskim oraz węgierskim, francuskim na poziomie podstawowym. Regularnie bierze udział w szkoleniach i konferencjach naukowych dotyczących diagnostyki i leczenia nowotworów skóry.",
    city: "Kraków",
    img: "wojciech_piskorski.png",
  },

  {
    name: "Aleksandra Litewka",
    title: "specjalizuje się w chirurgii ogólnej",
    description:
      "Absolwentka kierunku lekarskiego Collegium Medicum Uniwersytetu Jagiellońskiego w Krakowie. Pierwsze 2 lata szkolenia specjalizacyjnego w zakresie chirurgii ogólnej odbyła w Narodowym Instytucie Onkologii im. Marii Skłodowskiej-Curie w Krakowie. Aktualnie swoje szkolenie specjalizacyjne kontynuuje w Szpitalu Zakonu Bonifratrów św Jana w Krakowie. Ukończyła także studia podyplomowe na kierunku medycyna estetyczna Śląskiej Wyższej Szkoły Medycznej w Katowicach, z wynikiem bardzo dobrym. Uczestniczy w licznych szkoleniach, poszerzając swoją wiedzę oraz umiejętności praktyczne z zakresu medycyny estetycznej oraz chirurgii. Jest członkiem Polskiego Towarzystwa Chirurgicznego oraz Polskiego Towarzystwa Medycyny Estetycznej i Anti- Aging.",
    city: "Kraków",
    img: "aleksandra_litewka.png",
  },
  {
    name: "Izabela Karpińska",
    title: "",
    description: "rezydentka chirurgii ogólnej w Klinice Chirurgii Ogólnej, Onkologicznej, Metabolicznej i Stanów Nagłych oraz doktorantka Szkoły Doktorskiej Nauk Medycznych i Nauk o Zdrowiu UJCM przy II Katedrze Chirurgii Ogólnej UJCM. Ukończyła studia na kierunku lekarskim na Uniwersytecie Jagiellońskim – Collegium Medicum. Stale podnosi swoje kompetencje poprzez udział w licznych praktykach, stażach oraz certyfikowanych kursach doszkalających m in „Kurs dermatoskopowy” organizowany przez Akademię Dermatoskopii we Wrocławiu, „Kurs szycia chirurgicznego” oraz “Szycie chirurgiczne – poziom zaawansowany” organizowane przez Centrum Kształcenia Medycznego w Warszawie.",
    city: "Kraków",
    img: "Izabela-Karpinska.png",
  },
  {
    name: "Karolina Zinkow-Krzemyk",
    title: "",
    description: "Absolwentka kierunku lekarskiego Collegium Medicum Uniwersytetu Jagiellońskiego w Krakowie oraz studiów podyplomowych na kierunku medycyny estetycznej dla lekarzy na Śląskiej Wyższej Szkole Medycznej w Katowicach, które ukończyła z wyróżnieniem. Staż podyplomowy odbyła w Szpitalu im. Gabriela Narutowicza w Krakowie. Wiedzę z zakresu dermatoskopii zdobyła podczas kursów organizowanych przez Consultronix oraz w Akademii Dermatoskopii dr. Jacka Calika. Jest członkiem International Dermoscopy Society oraz Polskiego Towarzystwa Medycyny Estetycznej i Anty-Aging. Pracuje również jako lekarz medycyny estetycznej i aktywnie uczestniczy w szkoleniach z tej dziedziny. Zawsze kieruje się chęcią zdobycia jak najszerszej wiedzy i stara się stale rozwijać swoje umiejętności. Jest również autorką kilku prac naukowych.",
    city: "Kraków",
    img: "Zinkow-Krzemyk.png",
  },
  {
    name: "Agnieszka Garbacz",
    title: "",
    description: "Absolwentka Wydziału Lekarskiego Śląskiego Uniwersytetu Medycznego w Katowicach, gdzie w 2011 roku uzyskała dyplom lekarza. Jej pasja do chirurgii oraz chęć nieustannego doskonalenia zawodowego skłoniły ją do podjęcia specjalizacji w chirurgii ogólnej, którą ukończyła w 2020 roku, zdając Państwowy Egzamin Specjalistyczny.  Wykonuje liczne zabiegi chirurgiczne, takie jak usuwanie znamion, tłuszczaków, nacięcie ropni, punkcje oraz szycie ran, stosując najnowsze standardy medyczne i techniki operacyjne. ",
    city: "Kraków",
    img: "Agnieszka-Garbacz.png",
  },
  {
    name: "Marta Matysewicz",
    title: "",
    description: "Specjalistka chirurgii onkologicznej oraz chirurgii ogólnej. Na co dzień pracuje na stanowisku starszego asystenta w Oddziale Chirurgii Ogólnej i Onkologicznej Szpitala Specjalistycznego im. L. Rydygiera w Krakowie. Jej zainteresowania zawodowe obejmują chirurgię onkologiczną, ze szczególnym uwzględnieniem: nowotworów przewodu pokarmowego, nowotworów skóry, nowotworów piersi, a także chirurgię endokrynologiczną.",
    city: "Kraków",
    img: doctroPlaceholderFemale,
  },
  {
    name: "Bartłomiej Staszczak",
    title: "",
    description:
      "Absolwent Wydziału Lekarskiego Collegium Medicum Uniwersytetu Jagiellońskiego. Aktualnie w trakcie szkolenia specjalizacyjnego w Oddziale Klinicznym Chirurgii Ogólnej, Onkologicznej, Metabolicznej i Stanów Nagłych Szpitala Uniwersyteckiego w Krakowie. Członek Towarzystwa Chirurgów Polskich. W codziennej pracy zajmuje się diagnostyką i leczeniem chirurgicznym dorosłych. W kręgu jego szczególnych zainteresowań zawodowych znajdują się minimalnie inwazyjne zabiegi chirurgii laparoskopowej, ze szczególnym uwzględnieniem chirurgii onkologicznej.",
    city: "Kraków",
    img: "staszczak.png",
  },
  {
    name: "Eliza Siemaszko-Oniszczuk",
    title: "",
    description:
      "Jestem absolwentką kierunku lekarskiego na Uniwersytecie Jagiellońskim w Krakowie (2015-2021) Po odbyciu obowiązkowego stażu podyplomowego oraz blisko rocznym doświadczeniu pracy w Podstawowej Opiece Zdrowotnej, w sierpniu 2023r. rozpoczęłam wymarzoną rezydenturę z dermatologii i wenerologii w Szpitalu Uniwersyteckim w Krakowie, gdzie łączę pracę z pacjentami oraz dydaktykę przyszłych lekarzy - studentów Jagiellonian University Medical College. Od marca 2024 rozpoczęłam również studia podyplomowe z zakresu medycyny estetycznej na Warszawskim Uniwersytecie Medycznym. Stale poszerzam swoją wiedzę podczas udziału w licznych kursach, konferencjach i szkoleniach. Moja znajomość medycznego języka angielskiego na poziomie C1, potwierdzona certyfikatem, umożliwia mi kompetentne przyjmowanie pacjentów niepolskojęzycznych. Cechuje mnie wysoka kultura osobista, punktualność, rzetelność i empatia.",
    city: "Kraków",
    img: "siemaszkooniszczuk.png",
  },
];
const DOCTORS_GDANSK = [
  
  {
    name: "Magdalena Antoszewska",
    title: "",
    description:
      "Doktorantka Pierwszej Szkoły Doktorskiej Gdańskiego Uniwersytetu Medycznego. W trakcie specjalizacji z dermatologii i wenerologii w Klinice Dermatologii, Wenerologii i Alergologii Uniwersyteckiego Centrum Klinicznego w Gdańsku. ",
    city: "Gdańsk",
    img: "antoszewska.png",
  },
  {
    name: "Hubert Podgórski",
    title: "",
    description: "Absolwent Wydziału Lekarskiego Gdańskiego Uniwersytetu Medycznego. Uzyskał certyfikat ukończenia Kursu Wideodermatoskopii organizowanego przez Akademię Dermatoskopii prowadzoną przez dr Jacka Calika. Nieustannie poszerza swoją wiedzę poprzez uczestnictwo w szkoleniach z wideodermatoskopii jak i przez codzienną pracę. Jego głównym zainteresowaniem jest wykorzystanie modeli sztucznej inteligencji i USG w diagnostyce nowotworów skóry.",
    city: "Gdańsk",
    img: "HubertPodgorski.png",
  },
  {
    name: "Aleksandra Karpeka",
    title: "",
    description:
      "Absolwentka Grodzieńskiego Państwowego Uniwersytetu Medycznego na wydziale lekarskim (2013 - 2019). Dermatoskopią i badaniem znamion zainteresowała się już na studiach. Doświadczenie w dermatoskopii zdobywała na kursach oraz szkoleniach, w tym w Akademii Dermatoskopii dr n. med. Jacka Calika we Wrocławiu. Kładzie duży nacisk na podnoszenie kwalifikacji poprzez aktywny udział w konferencjach naukowych i szkoleniach z zakresu dermatologii, dermato- i trichoskopii, chirurgii oraz innych. Jest członkiem International Dermatoscopy Society.",
    city: "Gdańsk",
    img: "karpeka.png",
  },
  {
    name: "Olga Makuch",
    title: "",
    description: "Absolwentka Warszawskiego Uniwersytetu Medycznego. Aktualnie w trakcie specjalizacji z chirurgii ogólnej na oddziale chirurgii onkologicznej w Gdyńskim Centrum Onkologii. W swojej pracy kieruje się przede wszystkim dobrem pacjenta. Stara się, aby każda wizyta przebiegała w miłej, przyjaznej atmosferze i wiązała się z jak najmniejszym stresem. Jej zainteresowania koncentrują się głównie na chirurgii onkologicznej, a zwłaszcza na chirurgicznym leczeniu nowotworów skóry.",
    city: "Gdańsk",
    img: doctroPlaceholderFemale,
  },
  {
    name: "Katarzyna Grabowska",
    title: "",
    description: "Jestem absolwentką wydziału lekarskiego Collegium Medicum UMK w Bydgoszczy. W 2014 rozpoczęłam specjalizację z chirurgii ogólnej w Oddziale Chirurgicznym Powiatowego Centrum Zdrowia w Malborku . Tytuł specjalisty chirurgii ogólnej uzyskałam w 2021 roku. Obecnie pracuję w Oddziale Chirurgicznym Szpitala św Wincentego a Paulo w Gdyni. Uczestniczę w licznych kursach, konferencjach oraz szkoleniach stale podnosząc swoją wiedzę oraz umiejętności w zakresie chirurgii ogólnej.",
    city: "Gdańsk",
    img: doctroPlaceholderFemale,
  },
  {
    name: "Tomasz Rogowski",
    title: "",
    description: "Jestem absolwentem Akademii Medycznej w Gdańsku, którą ukończyłem w 1999 roku. Po zakończeniu studiów swoją pracę rozpocząłem na Oddziale Chirurgii Ogólnej Szpitala Powiatowego w Malborku. W roku 2007 uzyskałem tytuł specjalisty chirurgii ogólnej. W latach 2017-2019 odbywałem staż specjalizacyjny w Oddziale Chirurgii Onkologicznej Bydgoskiego Centrum Onkologii uzyskując w 2020 roku tytuł specjalisty chirurgii onkologicznej. Od 2020 roku pracuję w Szpitalu Św. Wincentego a Paulo w Gdyni, gdzie pełnię funkcję kierownika  Oddziału Chirurgii Ogólnej. Odbyłem szereg kursów i stażów z zakresu chirurgii kolorektalnej, herniologii, chirurgii rekonstrukcyjnej oraz leczenia ran w Polsce i Europie. Obecnie specjalizuję się w małoinwazyjnej chirurgii kolorektalnej.",
    city: "Gdańsk",
    img: doctroPlaceholderMale,
  },
];
const DOCTORS_KATOWICE = [
  {
    name: "Kamil Czepiel",
    title: "",
    description:
      "Specjalista chirurgii ogólnej i dyplomowany lekarz medycyny estetycznej. Ukończył studia na Wydziale Lekarskim Śląskiego Uniwersytetu Medycznego w Katowicach. Od wielu lat pracuje i dyżuruje w Oddziałach i Izbach Przyjęć Chirurgicznych. Nieustannie poszerza swoje kwalifikacje, biorąc czynny udział w licznych kongresach oraz certyfikowanych szkoleniach, stażach i kursach w Polsce oraz poza jej granicami. Przeprowadza konsultacje w języku angielskim i francuskim.",
    city: "Katowice",
    img: "kamil_czepiel.png",
  },

  {
    name: "Agnieszka Pociecha",
    title: "",
    description: "",
    city: "Katowice",
    img: doctroPlaceholderFemale,
  },


];
const DOCTORS_ZYWIEC = [
  {
    name: "Agnieszka Kotnis",
    title: "specjalistka chirurgii ogólnej i chirurgii onkologicznej",
    description:
      "Absolwentka Śląskiej Akademii Medycznej w Katowicach na wydziale lekarskim. Swoje zainteresowanie kieruje w stronę chirurgii onkologicznej oraz dermatochirurgii. Wykonuje także zabiegi z zakresu kriochirurgii oraz laseroterapii. Doświadczenie w badaniu znamion i wykrywania nowotworów skóry zdobyła pracując  w Beskidzkim Centrum Onkologii w Bielsku – Białej. Wciąż podwyższa swoje kwalifikacje biorąc udział w licznych szkoleniach i kursach.",
    city: "Żywiec",
    img: doctroPlaceholderFemale,
  },
  {
    name: "Maurycy  Jakubiec",
    title: "specjalista chirurgii ogólnej",
    description:
      "Certyfikowany ultrasonografista PTU, absolwent Śląskiej Akademii Medycznej w Katowicach na wydziale lekarskim. Ukończył studia podyplomowe w zakresie medycyny estetycznej  w Śląskiej Wyższej Szkole Medycznej w Katowicach , ukończył  kurs   „Czujność onkologiczna w praktyce – ogólnopolski cykl kursów doskonalących w zakresie profilaktyki i wczesnego wykrywania nowotworów oraz leczenia powikłań i bólu pacjentów onkologicznych . Od 2001 roku prowadzi Oddział Chirurgii  Krótkoterminowej w Żywcu. Swoje zainteresowanie kieruje w stronę chirurgii ogólnej małoinwazyjnej oraz dermatochirurgii. Wykonuje także zabiegi z zakresu kriochirurgii oraz laseroterapii.  Wciąż podwyższa swoje kwalifikacje biorąc udział w licznych  szkoleniach i kursach.",
    city: "Żywiec",
    img: "maurycy_jakubiec.png",
  },
];
const DOCTORS_STUFF = [
  {
    name: "Wiktoria Luchowska",
    title: "Koordynatorka ds. obsługi pacjenta",
    city: null,
    description:
      "",
    img: doctroPlaceholderFemale,
  },
  {
    name: "Joanna Polcyn",
    title: "Koordynatorka ds. obsługi pacjenta",
    city: null,
    description: "",
    img: "joanna_polcyn.png",
  },
  {
    name: "Irena Chmura",
    title: "Koordynatorka ds. obsługi pacjenta",
    city: null,
    description: "",
    img: "irena_chmura.png",
  },
  {
    name: "Mateusz Niklas",
    title: "Koordynator ds. obsługi pacjenta",
    city: null,
    description: "",
    img: "mateusz_niklas.jpeg",
  },
  
];

const CtaUmowSie = () => {
  return (
    <Link to="/kontakt" title="Umów się" className={GTM_LEAD_UMOW_SIE}>
      Umów się
    </Link>
  );
};

const AboutUsPage = ({ graph, location }) => {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    setLocations(LOCATIONS);
  }, [LOCATIONS]);

  return (
    <Layout location={location}>
      <Helmet
        bodyAttributes={{
          "bg-option": "about",
        }}
      >
        <title>O nas</title>
      </Helmet>

      <div className="about">
        <div className="heading">
          <h1>
            <span className="colored">Naszą misją jest</span> wyeliminowanie
            nowotworów skóry z życia milionów ludzi
          </h1>
        </div>
        <div className="ceo">
          <div className="content_wrapper">
            <h2>Działamy dla Was</h2>
            <div className="subheading">
              Wiemy dokładnie jak wiele potrafi odebrać choroba nowotworowa,
              dlatego wspólnie z wieloma specjalistami różnych dziedzin stawiamy
              czoła wyzwaniu i stajemy do walki z rakiem. Wierzymy, że razem
              zmniejszymy zachorowalność i umieralność z powodu nowotworów skóry
              w Polsce.
            </div>
            <div className="text">
              Co roku obserwujemy stały i dynamiczny wzrost zachorowań na
              czerniaka skóry. W ciągu ostatnich 30 lat ich liczba wzrosła
              niemal trzykrotnie. Z powodu braku ogólnodostępnego publicznego
              programu profilaktyki czerniaka wielu pacjentów umiera z powodu
              zbyt późnego rozpoznania choroby. Wykorzystując szybką i
              sprawdzoną metodę diagnostyczną, jaką jest dermatoskopowe badanie
              znamion zmieniamy te przerażające statystyki. Wprowadzenie
              dermatoskopowej oceny znamion do rutynowej diagnostyki poprawia
              wykrywalność czerniaka o ok. 30%.
            </div>
            <div className="signature">
              <div>
                Agnieszka Czyżewska, <span className="ceo_title">CEO</span>
              </div>
              <div className="medical_title">
                specjalistka onkologii klinicznej
              </div>
            </div>
          </div>
          <div className="img_wrapper">
            <PreviewCompatibleImage
              imageInfo={{
                image: graph.CeoImage,
                alt:
                  "Agnieszka Czyżewska - specjalistka onkologii klinicznej, CEO",
                title:
                  "Agnieszka Czyżewska - specjalistka onkologii klinicznej, CEO",
              }}
              styles={{
                position: "absolute",
              }}
            />
          </div>
        </div>
        <div className="map">
          <BubbleSection color="violet" reverse>
            <div className="container">
              <div className="cards">
                <div className="card_map">
                  <Map data={locations} className="nointeraction" />
                </div>
                <div>
                  <h2>Ogólnopolskie Centrum Badania Znamion</h2>
                  <div className="subheading">
                    Stworzyliśmy sieć ośrodków w całym kraju, które kompleksowo
                    zajmują się diagnostyką zmian skórnych. Nasi pacjenci
                    otrzymują profesjonalną opiekę od momentu pierwszego badania
                    wideodermatoskopowego, przez postawienie diagnozy, do
                    zakończenia procesu terapeutycznego.
                  </div>
                  <div className="text">
                    W naszych ośrodkach wykorzystujemy najwyższej klasy
                    urządzenia medyczne, w tym cyfrowe wideodermatoskopy,
                    umożliwiające mapowanie zmian skórnych oraz skrupulatną
                    archiwizację danych pacjenta. Współpracujemy z lekarzami
                    różnych specjalizacji, w tym z dermatologami, chirurgami i
                    onkologami, którzy w swojej codziennej praktyce zajmują się
                    leczeniem pacjentów z nowotworami skóry. Zapewniamy naszym
                    pacjentom profesjonalną opiekę i możliwość konsultacji
                    zarówno na etapie diagnostyki jak i leczenia choroby
                    nowotworowej.
                  </div>
                </div>
              </div>
            </div>
          </BubbleSection>
        </div>
        <div className="team">
          <span id="all" />
          <BubbleSection
            h2="Nasz zespół"
            h5="Nasz zespół tworzą doświadczeni lekarze, którzy wspólnie, z pasją działają na rzecz zmniejszenia umieralności z powodu nowotworów skóry w Polsce."
            color="blue"
          >
            <div className="container">
              <div className="cards">
                <DoctorList
                  data={DOCTORS_POZNAN}
                  graph={graph}
                  cityAnchor="poznan"
                />
                <DoctorList
                  data={DOCTORS_WARSZAWA}
                  graph={graph}
                  cityAnchor="warszawa"
                />
                <DoctorList
                  data={DOCTORS_WROCLAW}
                  graph={graph}
                  cityAnchor="wroclaw"
                />
                <DoctorList
                  data={DOCTORS_SZCZECIN}
                  graph={graph}
                  cityAnchor="szczecin"
                />
                <DoctorList
                  data={DOCTORS_KRAKOW}
                  graph={graph}
                  cityAnchor="krakow"
                />
                <DoctorList
                  data={DOCTORS_GDANSK}
                  graph={graph}
                  cityAnchor="gdansk"
                />
                <DoctorList
                  data={DOCTORS_KATOWICE}
                  graph={graph}
                  cityAnchor="katowice"
                />
                <DoctorList
                  data={DOCTORS_STUFF}
                  notMedical={true}
                  graph={graph}
                />
              </div>
            </div>
          </BubbleSection>
        </div>
        <Cta className="home_cta" constactCtaComponent={<CtaUmowSie />} />
      </div>
    </Layout>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { relativePath: { regex: "/people*/" } }) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 128, maxHeight: 128, quality: 100) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        CeoImage: allFile(filter: { relativePath: { regex: "/ceo.png/" } }) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 300, maxHeight: 300, quality: 95) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <AboutUsPage
        graph={{
          doctors: data.allFile.nodes,
          CeoImage: data.CeoImage.nodes[0],
        }}
        {...props}
      />
    )}
  />
);
